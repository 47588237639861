// Common
body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
/* helper classes */
.bg-white {
  background-color: $white;
}
.force-opaque {
  opacity: 1 !important;
}
.bg-white-opaque {
  background-color: RGBA(255, 255, 255, 1);
}
.no-border {
  border: 0;
}
