@import "src/theme/theme";
@import "../node_modules/flag-icon-css/css/flag-icon.css";

html {
  font-size: 1rem;
}

body {
  padding-right: 1rem;
  padding-left: 1rem;
  scrollbar-face-color: #c4c4c4 !important;
  scrollbar-track-color: $white !important;
}

button,
textarea,
input,
a {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 0.4em !important;
  height: 0.4em !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c4c4c4 !important;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: $white !important;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem;
  }
  body {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
  body {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
  body {
    padding-right: 0;
    padding-left: 0;
  }
}

// Global rule to override ladda default hover color
.vox-btn[disabled] .ladda-button {
  background-color: #e5e9f0;
  .ladda-label {
    color: #868990;
  }
}

.ladda-button[disabled],
.ladda-button[data-loading] {
  border: 0px solid transparent;
}

.ladda-button {
  background-color: unset!important;
}

// Ngb datepicker arrow
.ngb-dp-arrow-btn{
  color: $black;
  &:hover{
    color: $black;
  }
}

// Agents modal in partners
.modal-agents {
  .modal-dialog{
    max-width: 1200px !important;
  }
}

// Typeahead
ngb-typeahead-window{
  max-height: 170px;
  overflow-y: auto;
}

/*
* There is a problem with modal backdrop z-index after updating ng-bootstrap.
* We found solution there - https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
*/
ngb-modal-backdrop {
  z-index: 1050 !important;
}