// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: ($spacer / 2) !default;

// Navbar

$navbar-padding-y: ($spacer / 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: (
  $font-size-base * $line-height-base + $nav-link-padding-y * 2
) !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($black, 0.5) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
