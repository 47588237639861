// Font Variables
$vox-font-path: "../fonts" !default;
$vox-gtEestiPro-font: "GT-Eesti-Pro", "Arial", "Helvetica", sans-serif;

// Color variables
$black: #000000;
$white: #ffffff;
$bright-crimson: #ff3465;
$royal-blue: #0066ff;
$aquamarine: #25edb5;
$gold: #fde062;
$peach: #ff8d7a;
$purple: #6515dd;
$dark-grey: #868990;
$medium-grey: #b5bac2;
$light-grey: #e5e9f0;
$lighter-grey: #f5f7f9;
